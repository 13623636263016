import React from 'react';
import {Close, Edit} from "@mui/icons-material";
import GreenStep216 from "./greenStep216";
import {useTranslation} from "react-i18next";

const GreenStep216Show = (props) => {
  const { t } = useTranslation();
  const {comment, commentIndex, commentItem, isDeleted, greenStep, dataType} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const [addMore, setAddMore] = React.useState(false);

  return (
    <React.Fragment>
      <p className="completedCommentText">
        <span>{commentItem.comment}</span>
        {(commentItem.customComment || (userData && userData.user.id === commentItem.user_id)) && !isDeleted &&
          <span
            className="completedAddNewIcon categoryBgColor editCommentBtn hover-effect"
            onClick={() => {
              props.changeCommentState(commentItem.comment, greenStep.new_company_green_steps[0].id, commentIndex)
            }}
          >
            <Edit/>
          </span>
        }

        {comment.length === commentIndex+1 &&
          <span className="categoryTextColor addMoreText" onClick={() => setAddMore(true)}>
            {t('pluses_page.add_year')}
         </span>
        }

      </p>
      {!commentItem.hasOwnProperty('customComment') && userData && userData.user.id === commentItem.user_id && !isDeleted &&
        <p
          className="companyCompletedIcon categoryBorderColor true hover-effect"
          onClick={() => {
              props.removeComment(greenStep.new_company_green_steps[0].id, commentIndex, comment, greenStep.new_green_step_category_id, dataType)
          }}
        >
          <Close/>
        </p>
      }

      {addMore &&
        <div className='customAddMoreYearInCompleted'>
          <GreenStep216 comment={comment} item={false} commentIndex={99999} greenStep={greenStep} close={() => setAddMore(false)} />
        </div>
      }

    </React.Fragment>
  )
};

export default GreenStep216Show