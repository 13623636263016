import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import {createCompanyGreenStep, editComment} from "../../../../store/actions/greenStep";
import {useDispatch} from "react-redux";

const GreenStep206 = (props) => {
  const {item, isInstitution} = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;

  const [timer, setTimer] = useState(null);
  const [governance, setGovernance] = useState({"id": 0, "employees": '', "woman": '', "kvar": '', "men": '', "other": ''});

  useEffect(() => {
    if(props.item && props.item.id === 206) {
      if(item.new_company_green_steps.length>0 && item.new_company_green_steps[0]['comment'] && item.new_company_green_steps[0]['comment'].length>0) {
        let comment = JSON.parse(item.new_company_green_steps[0]['comment']);
          setGovernance({...comment, 'id':item.new_company_green_steps[0].id});
      }
    }
  }, [props.item]);

  const onChangeGovernance = (item, value) => {
    if(value < 1 && value !== '') { return}

    let w,k,m,o = 0;
    let data = Object.assign({}, governance);
    data[item] = value;

    w = parseInt(data.woman) || 0;
    k = parseInt(data.kvar) || 0;
    m = parseInt(data.men) || 0;
    o = parseInt(data.other) || 0;
    data['total'] = w+k+m+o;

    setGovernance(data);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if(data.id>0) {
        dispatch(editComment(data.id, data, item.main_category-1, 'comment'));
      } else {
        let newGreenStep = {
          "new_green_step_id": 206,
          "comment": data,
          "irrelevant": false,
          "reason": []
        };
        dispatch(createCompanyGreenStep(newGreenStep, companyId, item.main_category-1));
      }
    }, 1000);

    setTimer(newTimer);

  };

  return (
    <div className={"customGreenStep gs3"}>
      <div className="scoreAndFavoriteContainer">
        <p className="score categoryTextColor">{item.score} {t('pluses_page.points')}</p>
      </div>

        <div className="title categoryTextColor">{t('pluses_page.equality_board')}</div>
        <div className="users">
          <div className="userType">
            <label>{t('pluses_page.equality_women')}</label>
            <Input
              name="woman"
              type="number"
              value={governance.woman || ""}
              className="form-control"
              disabled={isInstitution && item.type === "municipality"}
              onChange={event => onChangeGovernance("woman", event.target.value)}
            />
            {governance.total > 0 && governance.woman &&
              <span className="percent">{Math.round(governance.woman * 100 / governance.total)}%</span>
            }
          </div>
          <div className="userType">
            <label>{t('pluses_page.equality_nonbinary')}</label>
            <Input
              name="woman"
              type="text"
              value={governance.kvar || ""}
              className="form-control"
              disabled={isInstitution && item.type === "municipality"}
              onChange={event => onChangeGovernance("kvar", event.target.value)}
            />
            {governance.total > 0 && governance.kvar &&
              <span className="percent">{Math.round(governance.kvar * 100 / governance.total)}%</span>
            }
          </div>
          <div className="userType">
            <label>{t('pluses_page.equality_men')}</label>
            <Input
              name="men"
              type="number"
              value={governance.men || ""}
              className="form-control"
              disabled={isInstitution && item.type === "municipality"}
              onChange={event => onChangeGovernance("men", event.target.value)}
            />
            {governance.total > 0 && governance.men &&
              <span className="percent">{Math.round(governance.men * 100 / governance.total)}%</span>
            }
          </div>
          <div className="userType">
            <label>{t('pluses_page.equality_other')}</label>
            <Input
              name="other"
              type="number"
              value={governance.other || ""}
              className="form-control"
              disabled={isInstitution && item.type === "municipality"}
              onChange={event => onChangeGovernance("other", event.target.value)}
            />
            {governance.total > 0 && governance.other &&
              <span className="percent">{Math.round(governance.other * 100 / governance.total)}%</span>
            }
          </div>
        </div>

        <div className="comment">
          <span>{t('pluses_page.equality_note')}:</span> <a href="https://www.althingi.is/altext/stjt/2010.013.html"
                                                            target="_blank" rel="noopener noreferrer">
          {t('pluses_page.equality_law')} </a>
          {t('pluses_page.equality_lawtext')}
        </div>

      </div>
      )
      };

      export default GreenStep206