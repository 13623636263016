import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {Check} from '@mui/icons-material';
import {Input, Label} from "reactstrap";
import Select from "react-select";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {editComment} from "../../../../store/actions/greenStep";
import {AddCommas, RemoveNonNumeric} from "../../../Carbon/components/helpers";
import {customDropdownStyles} from "../../../../helpers/dropdown";


const yearOptions = () => {
  let years = [];
  for(let i=0; i<=13; i++) {
    years.push({label: 2010+i, value: 2010+i})
  }
  return years;
};

const GreenStep216 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [project, setProject] = useState('');
  const [year, setYear] = useState('');
  const [amount, setAmount] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if(props.item) {
      setProject(item.project);
      setYear(item.year);
      setAmount(item.amount);
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(project.length > 0 && year > 0 && amount.length > 0) {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `Árið ${year} fjárfesti fyrirtækið ${amount} ISK í samfélagsverkefninu ${project}.`,
        "comment_en": `In ${year}, the company invested ${amount} ISK in the community project ${project}.`,
        'project': project,
        'year': year,
        'amount': RemoveNonNumeric(amount),
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="row customGreenStep216Completed" style={{alignItems: 'center'}}>
      <div className="col-5">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.what_community_supported')}</Label>
        <div className="inputCol">
          <Input
            id="text"
            type="text"
            placeholder="Hvaða samfélagsverkefni var stutt?"
            className="form-control goalField"
            value={project}
            onChange={(event) => {
              setProject(event.target.value)
            }}
            invalid={error && project.length === 0}
          />
        </div>
      </div>

      <div className="col-3">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.year')}</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => setYear(event.value)}
          styles={customDropdownStyles('#FD974F')}
          options={yearOptions()}
          value={{label: year, value: year}}
          className={`react-select ${error && year.length === 0 && 'has-error'}`}
        />
      </div>

      <div className="col-3">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.amount')}</Label>
        <div className="inputCol">
          <Input
            id="text"
            type="text"
            placeholder="Upphæð"
            className="form-control goalField"
            value={amount}
            onChange={(event) => {
              setAmount(AddCommas(RemoveNonNumeric(event.target.value)))
            }}
            invalid={error && amount.length === 0}
          />
          <span className="categoryTextColor">ISK</span>
        </div>
      </div>

      <div className="col-1">
        <div className="companyCompletedIcon categoryBorderColor true" style={{marginTop: '40px'}} onClick={()=>saveEditComment()}><Check/></div>
      </div>
    </div>
  )
};

export default GreenStep216