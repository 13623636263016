import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Check} from '@mui/icons-material';
import {Input, Label} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {updateCompanyGreenStep} from "../../../../store/actions/greenStep";
import {useTranslation} from "react-i18next";

const GreenStep62 = (props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const [error, setError] = useState(false);
  const [totalCar, setTotalCar] = useState(0);
  const [electricCar, setElectricCar] = useState(0);

  useEffect(() => {
    if(props.item) {
      setTotalCar(item.totalCar);
      setElectricCar(item.electricCar)
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(totalCar>0 && electricCar>0) {
      let score = Math.round((electricCar / totalCar)*greenStep.score);
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `${electricCar} af ${totalCar} bílum ganga fyrir rafmagni`,
        "comment_en": `${electricCar} of ${totalCar} cars run on electricity`,
        "totalCar": totalCar,
        "electricCar": electricCar,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      let data = {
        "id": greenStep.new_company_green_steps.length>0 ? greenStep.new_company_green_steps[0].id : null,
        "new_green_step_id": greenStep.id,
        "slider_score": score,
        "slider_score_percentage": score,
        "comment": newComment,
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };
      dispatch(updateCompanyGreenStep(data, userData.companyId, greenStep.main_category-1));
      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="customCompletedCar" >
      <div className="row">
        <div className="col-6">
          <Label className="col-form-label categoryTextColor" style={{paddingTop: 0}}>{t('pluses_page.number_of_company_cars')}</Label>
          <Input
            id="text"
            type="number"
            className="form-control goalField"
            value={totalCar}
            onChange={(event) =>{setTotalCar(event.target.value)}}
            invalid={error && totalCar === 0}
          />
        </div>
        <div className="col-4">
          <Label className="col-form-label categoryTextColor" style={{paddingTop: 0}}>{t('pluses_page.electricity')}</Label>
          <Input
            id="text"
            type="number"
            className="form-control goalField"
            value={electricCar}
            min={0}
            max={totalCar}
            onChange={(event) =>{event.target.value > totalCar ? setElectricCar(totalCar) : setElectricCar(event.target.value)}}
            invalid={error && electricCar === 0}
          />
        </div>
        <div className="col-2">
          <div className="companyCompletedIcon categoryBorderColor true"  style={{marginTop: '40px'}} onClick={() => saveEditComment()}>
            <Check/>
          </div>
        </div>
      </div>
    </div>
  )
};

export default GreenStep62