import React from 'react';
import { useTranslation } from 'react-i18next';
import {Check} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import Switch from "react-switch";
import {createCompanyGreenStep, deleteCompanyGreenStep} from "../../../store/actions/greenStep";

const IrrelevantSwitch = (props) => {
  const {item, companyGreenStep} = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const isIrrelevant = props.companyGreenStep && props.companyGreenStep.hasOwnProperty('irrelevant') && props.companyGreenStep.irrelevant === true;

  const handleCheck = (id, irrelevant) => {
    let data = {
      "new_green_step_id": id,
      "comment": [],
      "irrelevant": irrelevant,
      "reason": [],
      "pdf": null,
      "pdf_name": null
    };

    dispatch(createCompanyGreenStep(data, companyId, item.main_category-1));
  };

  const handleUncheck = (id) => {
    dispatch(deleteCompanyGreenStep(id, item.main_category-1, 'deleteCompletely'));
  };

  return (
    <div
      className={`companyCompletedToggleBtns ${companyGreenStep ? isIrrelevant ? 'irrelevant' : 'notIrrelevant' : ''}`}>
      {item.irrelevant &&
        <div className="companyCompleted irrelevant">
          <Switch
            id={'card_carousel_' + item.id + "irrelevant-list"}
            checked={isIrrelevant}
            onChange={() => companyGreenStep ? handleUncheck(companyGreenStep.id) : handleCheck(item.id, true)}
            activeBoxShadow="0px 0px 1px 2px #f3f3f3"
            checkedHandleIcon={
              <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                <Check/>
              </div>
            }
            className={`react-custom-switch ${isIrrelevant && 'active'}`}
          />
          <div className="companyCompletedTitle">{t('pluses_page.irrelevant')}</div>
        </div>
      }
    </div>
  )
};

export default IrrelevantSwitch