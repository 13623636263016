import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Check} from '@mui/icons-material';
import {Input, Label} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {editComment} from "../../../../store/actions/greenStep";

const GreenStep213 = (props) => {
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [first, setFirst] = useState('');
  const [second, setSecond] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if(props.item) {
      setFirst(item.first);
      setSecond(item.second);
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(first>=0 && second>=0) {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `Árið 2023 voru ${first} starfsfólks hjá fyrirtækinu í hlutastarfi og ${second} starfsfólks í verktöku og/eða ráðgjöf.`,
        "comment_en": `In 2023, ${first} of employees at the company were part-time and ${second} of employees were in contracting and/or consulting.`,
        "first" : first,
        "second" : second,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="row customGreenStep210Completed" style={{alignItems: 'center'}}>
      <div className="col-5">
        <Label className="col-form-label categoryTextColor">Prósenta starfsfólks í hlutastarfi árið 2023?</Label>
        <div className="inputCol">
          <Input
            id="text"
            type="number"
            className="form-control goalField"
            value={first}
            onChange={(event) => {
              event.target.value <= 100 ? setFirst(event.target.value) : setFirst(100)
            }}
            invalid={error && first.length === 0}
          />
          <span className="categoryTextColor">%</span>
        </div>
      </div>

      <div className="col-5">
        <Label className="col-form-label categoryTextColor">Prósenta þeirra sem voru í verktöku og/eða ráðgjöf árið 2023?</Label>
        <div className="inputCol">
          <Input
            id="text"
            type="number"
            className="form-control goalField"
            value={second}
            onChange={(event) => {
              event.target.value <= 100 ? setSecond(event.target.value) : setSecond(100)
            }}
            invalid={error && second.length === 0}
          />
          <span className="categoryTextColor">%</span>
        </div>
      </div>
      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" style={{marginTop: '50px'}} onClick={()=>saveEditComment()}><Check/></div>
      </div>
    </div>
  )
};

export default GreenStep213