import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import i18n from "../../../../i18n";
import {Check} from '@mui/icons-material';

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch} from "react-redux";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";
import IrrelevantSwitch from "../irrelevantSwitch";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep177 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);


  const handleCheck = () => {
    if(comment.length>3) {
      let data = {
        "new_green_step_id": item.id,
        "comment": [{
          "comment": comment,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        }],
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setComment('');
      setError(false);
    } else {
      setError(true);
    }
  };


  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </div>

      <IrrelevantSwitch item={item} companyGreenStep={companyGreenStep}/>

      <p className="categoryTextColor">{t('pluses_page.add_rationale_enforced')}</p>

      <div className="row" style={{alignItems: 'center'}}>
        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
          <Input
            id="text"
            type="text"
            className="form-control goalField"
            value={comment}
            onChange={(event) =>{setComment(event.target.value)}}
            invalid={error && comment.length === 0}
          />
        </div>
        <div className="col-2">
          <div className={`completeBtn categoryBgColor`} onClick={() => handleCheck()}>
            {t('save')}
          </div>
        </div>
      </div>

    </React.Fragment>
  )
};

export default GreenStep177