import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {customDropdownStyles} from "../../../../helpers/dropdown";
import {createCompanyGreenStep, getUnGoals} from "../../../../store/actions/greenStep";
import {LoaderSpinner} from "../../../../components/Common/Loader";
import {Close} from "@mui/icons-material";
import IrrelevantSwitch from "../irrelevantSwitch";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep203 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';
  const {unGoals, loadingUnGoal} = useSelector( state => state.greenStep );

  const [error, setError] = useState(false);
  const [state, setState] = useState([{
    'goal': '',
    'goalValue': '',
    'subGoal': '',
    'subGoalValue': '',
    'explanation': '',
    "user_id": userData.user.id,
    "name": userData.user.name,
    "date": HelperGetCurrentDate(),
    "customComment": true
  }]);


  useEffect(() => {
    if(unGoals.length === 0) {
      dispatch(getUnGoals());
    }
  },[]);

  const onChangeState = (value, item, index) => {
    let copyState = [...state];
    copyState[index][item] = value;
    setState(copyState);
  };

  const onChangeGoal = (value, item, index) => {
    let copyState = [...state];
    copyState[index][item] = value;
    copyState[index][item+'Value'] = value.value;
    setState(copyState);
  };

  const handleSave = () => {
    let checkValid = false;
    state.forEach(item => {
      if(item.goal === '' || item.subGoal === '' || item.explanation === '') {
        checkValid = true;
      }
    });

    if(checkValid) {
      setError(true);
    } else {
      let data = {
        "new_green_step_id": 203,
        "comment": state,
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null,
        "link": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
    }
  };

  const addMore = () => {
    let copyState = [...state];
    copyState.push({
      'goal': '',
      'goalValue': '',
      'subGoal': '',
      'subGoalValue': '',
      'explanation': '',
      "user_id": userData.user.id,
      "name": userData.user.name,
      "date": HelperGetCurrentDate(),
      "customComment": true
    });
    setState(copyState);
  };

  const removeItem = (index) => {
    let copyState = [...state];
    copyState.splice(index, 1);
    setState(copyState);
  }

  if (loadingUnGoal) return (<LoaderSpinner/>);

  return (
    <div className="customGreenStep203">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </div>

      <IrrelevantSwitch item={item} companyGreenStep={companyGreenStep}/>

      {
        state.map((item, index) => {
          return (
            <div className={"unGoalItem"} key={index+"customUnGoal"}>
              <p className="titleCopy">{t('pluses_page.choose_goal')}</p>
              <div className="row">
                <div className="col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-7 inputCol">
                  <Select
                    placeholder={t('select')}
                    isSearchable={false}
                    onChange={(event) => onChangeGoal(event, 'goal', index)}
                    styles={customDropdownStyles('#4433CD')}
                    options={unGoals.results ? unGoals.results.filter(f => f.parent_id === null).map(item => {return {label: item.goal, value: item.id}}) : []}
                    className={`react-select ${error && state[index].goal.length === 0 && 'has-error'}`}
                  />
                </div>
              </div>

              <p className="titleCopy">{t('pluses_page.select_subgoal')}</p>
              <div className="row">
                <div className="col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-7 inputCol">
                  <Select
                    placeholder={t('select')}
                    isSearchable={false}
                    onChange={(event) => onChangeGoal( event, 'subGoal', index)}
                    styles={customDropdownStyles('#4433CD')}
                    options={unGoals.results && state[index].goalValue ? unGoals.results.filter(f => f.parent_id === state[index].goalValue).map(item => {return {label: item.goal, value: item.id}}) : []}
                    className={`react-select ${error && state[index].subGoal.length === 0 && 'has-error'}`}
                  />
                </div>
              </div>

              <p className="titleCopy">{t('pluses_page.include_explanation_how_company_relates_goal')}</p>
              <div className="row">
                <div className="col-sm-11 col-md-10 col-lg-10 col-xl-10 col-xxl-10 inputCol">
                  <Input
                    name="action_input"
                    type="text"
                    className="form-control"
                    value={state[index].explanation}
                    onChange={(event) => onChangeState(event.target.value, 'explanation', index)}
                    invalid={error && state[index].explanation.length === 0}
                  />
                </div>
                <div className="col-1">
                  {index > 0 &&
                    <div className={`companyCompletedIcon categoryBorderColor true remove`}
                         onClick={() => removeItem(index)}
                    >
                      <Close/>
                    </div>
                  }
                </div>
              </div>

            </div>
          )
        })
      }


      <div className="saveRow">
        <div className="addMore" onClick={() => addMore()}>Bæta við heimsmarkmiði</div>

        <div className="saveBtn categoryBgColor" onClick={() => handleSave()}>
          {t('save')}
        </div>
      </div>


    </div>
  )
};

export default GreenStep203