import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
    apiKey: '2c74cd2dfe5f137f9e5b7143f41da25d',
    plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '2c74cd2dfe5f137f9e5b7143f41da25d' })

export default Bugsnag;