import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch} from "react-redux";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";
import IrrelevantSwitch from "../irrelevantSwitch";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep210 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [percentage, setPercentage] = useState('');
  const [error, setError] = useState(false);


  const handleCheck = () => {
    if(percentage>0) {
      let data = {
        "new_green_step_id": item.id,
        "comment": [{
          "comment": `Hlutfall launa og bónusgreiðsla forstjóra miðað við hlutfall meðallauna starfsfólks í fullu starfi er ${percentage}:1`,
          "comment_en": `The percentage of the CEO's salary and bonus payment compared to the percentage of the average salary of full-time employees is ${percentage}:1`,
          "percentage" : percentage,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        }],
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    } else {
      setError(true);
    }
  };


  return (
    <div className="customGreenStep">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
            :
            desc
        }
      </div>

      <IrrelevantSwitch item={item} companyGreenStep={companyGreenStep}/>

      <div className="customGreenStep">
        <p className="">{t('pluses_page.please_enter_ratio')}</p>

        <div className="row">
          <div className="col-10 inputCol">
            <Input
              id="text"
              type="number"
              className="form-control goalField"
              value={percentage}
              onChange={(event) => {
                setPercentage(event.target.value)
              }}
              invalid={error && percentage.length === 0}
            /> : 1
          </div>
          <div className="col-2 save">
            <div className="saveBtn categoryBgColor" onClick={() => handleCheck()}>
              {t('save')}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
};

export default GreenStep210