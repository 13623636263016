import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import RenderPdfFileContainer from "../renderPdfFileContainer";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import IrrelevantSwitch from "../irrelevantSwitch";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line


const GreenStep202 = (props) => {
  const { t } = useTranslation();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);

  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </div>

      <IrrelevantSwitch item={item} companyGreenStep={companyGreenStep}/>

      <p className="categoryTextColor"> {t('pluses_page.add_rationale_enforced')} </p>

      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        <Input
          id="text"
          type="text"
          className="form-control goalField"
          value={comment}
          onChange={(event) =>{setComment(event.target.value)}}
          invalid={error && comment.length === 0}
        />
      </div>

      <br/>

      <div className="confirm_container">
        <div className="confirm_title categoryTextColor">
          {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
        </div>
        <div className="confirm_title ">
          {currentLang === 'en' && item.pdf_description2_en ? item.pdf_description2_en : item.pdf_description2}
        </div>

        <RenderPdfFileContainer
          categoryItem={item}
          mainCategoryIndex={item.main_category-1}
          key={"renderBigCategory_mainItem_" + item.main_category-1 + "RenderPdfFileContainer"}
          requiredFrom={true}
          formValid={comment !== ''}
          changeErrorStatus={(status) => setError(status)}
          comment={[{
            "comment": comment,
            "user_id": userData.user.id,
            "name": userData.user.name,
            "date": HelperGetCurrentDate(),
            "customComment": true
          }]}
        />
      </div>


    </React.Fragment>
  )
};

export default GreenStep202