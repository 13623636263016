import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getRegistrationsByKennitala = (kennitala) => async dispatch => {
  dispatch({type: actionTypes.GET_REGISTRATION_COMPANY_KENNITALA_START });
  try {
    const res = await axios.get(`registration/get_company/${kennitala}`);
    dispatch({type: actionTypes.GET_REGISTRATION_COMPANY_KENNITALA_SUCCESS, data: res.data});
  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    let msg = res && res.message === "Details for this kennitala not found" ? 'Upplýsingar um þessa kennitölu voru ekki fundnar' : errorMessage
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: msg, style:'error'});
    dispatch({type: actionTypes.GET_REGISTRATION_COMPANY_KENNITALA_FAIL});
  }
};

export const getRegistrationsPlan = () => async dispatch => {
  dispatch({type: actionTypes.GET_REGISTRATION_PLAN_START });
  try {
    const res = await axios.get(`plan`);
    dispatch({type: actionTypes.GET_REGISTRATION_PLAN_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
    dispatch({type: actionTypes.GET_REGISTRATION_PLAN_FAIL});
  }
};

export const registerCompany = (data) => async dispatch => {
  dispatch({type: actionTypes.REGISTER_COMPANY_START });
  try {
    const res = await axios({
      url: 'registration/onboard',
      method: 'POST',
      data: data
    });

    if(res.data.message === 'Tókst að klára' && res.data.code === 200) {
      dispatch({type: actionTypes.REGISTER_COMPANY_SUCCESS, data: res.data});
      dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: res.data.message, style:'success'});
    } else {
      dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
    }

  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
    dispatch({type: actionTypes.REGISTER_COMPANY_FAIL});
  }
};

export const verifyOnboarding = (id) => async dispatch => {
  dispatch({type: actionTypes.VERIFY_ONBOARDING_START });
  try {
    const res = await axios.get(`registration/verify_onboarding/${id}`);
    dispatch({type: actionTypes.VERIFY_ONBOARDING_SUCCESS, data: res.data});
  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
    dispatch({type: actionTypes.VERIFY_ONBOARDING_FAIL});
  }
};