import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input, Label} from "reactstrap";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch, useSelector} from "react-redux";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";
import {Close} from "@mui/icons-material";
import Select, { components } from "react-select";
import {customGreenStepDropdownStyles} from "../../../../helpers/dropdown";
import {createUpdateCertificate, deleteCertificate, getCertificates} from "../../../../store/actions/certificate";
import {LoaderSpinner} from "../../../../components/Common/Loader";
import FileUploadPdf from "../../../../components/Common/CustomUpload/FileUploadPdf";

const Option = ({ children, innerProps, ...props }) => {
  const { t } = useTranslation();
  const {selectOption, selectProps, data} = props;
  const {optionDelete} = selectProps;

  const clickWithoutSelect = (e, callback) => {
    callback(data);
    e.stopPropagation();
  };

  const onMouseDown = {
    label: () => selectOption(data),
    delete: (e) => clickWithoutSelect(e, optionDelete)
  };

  const {onClick, ...newInnerProps} = innerProps;
  return (
    <components.Option {...props} innerProps={newInnerProps}>
      <span onClick={onMouseDown.label}>{children}</span>
      {data.companyId !== null &&
        <button onClick={onMouseDown.delete}>{t('delete')}</button>
      }
    </components.Option>
  );
};

const GreenStep219 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData ? userData.companyId : 0;
  const currentLang = i18n.language || 'is';
  const {loadingCertificate, certificates} = useSelector( state => state.certificate );

  const [error, setError] = useState(false);
  const [state, setState] = useState([{
    'certificate': {},
    'pdf': '',
    'pdfName': '',
    'link': '',
  }]);
  const [newCertificate, setNewCertificate] = useState('');

  useEffect(() => {
    if(certificates.length === 0) {
      dispatch(getCertificates(companyId));
    }
  },[]);

  const handleSave = () => {
    let checkValid = false;

    state.forEach(item => {
      if(Object.keys(item.certificate).length === 0 || (item.pdf === '' && item.link === '')) {
        checkValid = true;
      }
    });

    if(checkValid) {
      setError(true);
    } else {
      let commentsData = [];
      state.forEach(item => {
        commentsData.push({
          "comment": `Tegund vottunar eða umhverfisstjórnunarkerfis - ${item.certificate.label}.`,
          "comment_en": `Type of certification or environmental management system - ${item.certificate.label}.`,
          'certificate': item.certificate,
          'pdf': item.pdf,
          'pdfName': item.pdfName,
          'link': item.link,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        });
      });

      let data = {
        "new_green_step_id": item.id,
        "comment": commentsData,
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    }
  };

  const addMore = () => {
    let copyState = [...state];
    copyState.push({
      'certificate': {},
      'pdf': '',
      'pdfName': '',
      'link': '',
    });
    setState(copyState);
  };

  const onChangeState = (value, item, index) => {
    let copyState = [...state];
    copyState[index][item] = value;
    setState(copyState);
  };

  const removeItem = (index) => {
    let copyState = [...state];
    copyState.splice(index, 1);
    setState(copyState);
  }

  const changePdf = (value, fileName, index) => {
    let copyState = [...state];
    copyState[index]['pdf'] = value;
    copyState[index]['pdfName'] = fileName;
    setState(copyState);
  };

  const addNewCertificate = (index) => {
    if(newCertificate.length>0) {
      let data = {
        name: newCertificate,
        nameState: false,
        number: '',
        link:'',
        description: '',
        type:"company",
        logo: '',
        company_id: companyId
      }
      onChangeState({}, 'certificate', index)
      dispatch(createUpdateCertificate(data, false));
    }
  }

  const handleDeleteCertificate = (option) => {
    dispatch(deleteCertificate(option.value));
  }

  if (loadingCertificate) return (<LoaderSpinner/>);

  return (
    <div className="customGreenStep219">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      {state.map((sitem, index) => {
        return (
          <div className="customGreenStep" key={"customGreenStep215"+index}>
            <div className="row">
              <div className="col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-7">
                <label className="categoryTextColor">Tegund vottunar eða umhverfisstjórnunarkerfis</label>
                <Select
                  placeholder={t('select')}
                  isSearchable={false}
                  components={{Option}}
                  onChange={(event) => onChangeState(event, 'certificate', index)}
                  styles={customGreenStepDropdownStyles()}
                  options={certificates.results ? [...certificates.results.map(item => {
                    return {label: item.name, value: item.id, companyId: item.company_id}
                  }), {label: 'Annað', value: 0, companyId: null}] : []}
                  value={state[index].certificate}
                  className={`react-select ${error && Object.keys(state[index].certificate).length === 0 && 'has-error'}`}
                  optionDelete={handleDeleteCertificate}
                />
              </div>

              <div className="col-1">
              {index > 0 &&
                  <div className={`companyCompletedIcon categoryBorderColor true remove`}
                       onClick={() => removeItem(index)}
                  >
                    <Close/>
                  </div>
                }
              </div>
            </div>

            {Object.keys(state[index].certificate).length >0 && state[index].certificate.value === 0 &&
              <div className="row addNewCertif">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 inputCol">
                  <Input
                    name="action_input"
                    type="text"
                    className="form-control"
                    value={newCertificate}
                    step={{width: '100%'}}
                    onChange={(event) => setNewCertificate(event.target.value)}
                  />
                </div>
                <div className="col-sm-4 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="saveBtn categoryBgColor" onClick={() => addNewCertificate(index)}>
                    {t('save')}
                  </div>
                </div>
              </div>
            }

            <br/>

            <div className="row">
              <div className="col-12">
                <div className="confirm_container">
                  <div className="confirm_title categoryTextColor">
                    {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
                  </div>

                  <div className="pdf">
                    <FileUploadPdf
                      onUpload={(data, fileName) => changePdf(data, fileName, index)}
                      msg={t('pluses_page.pdf')}
                      fileUploadKey={"renderBigCategory_mainItem_" + item.main_category-1 + "_gs219_"+index}
                    />
                  </div>

                  <div className="pdfContainerfromLink">
                    <Label className="form_label categoryTextColor">{t('pluses_page.link_to_data')}</Label>
                    <div className="row">
                      <div className="col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-7 inputCol">
                        <Input
                          name="action_input"
                          type="text"
                          className="form-control"
                          value={state.link}
                          step={{width: '100%'}}
                          onChange={(event) => onChangeState(event.target.value, 'link', index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
      })}


      <div className="saveRow">
        <div className="addMore categoryTextColor" onClick={() => addMore()}>Bæta við vottun</div>

        <div className="saveBtn categoryBgColor" onClick={() => handleSave()}>
          {t('save')}
        </div>
      </div>

    </div>
  )
};

export default GreenStep219