import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Check} from '@mui/icons-material';
import {Input, Label} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {editComment} from "../../../../store/actions/greenStep";
import {useTranslation} from "react-i18next";

const GreenStep207 = (props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [assessor, setAssessor] = useState('');
  const [placePublication, setPlacePublication] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if(props.item) {
      setAssessor(item.assessor);
      setPlacePublication(item.placePublication);
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(assessor.length>3 && placePublication.length>3) {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `Sjálfbærniupplýsingar fyrirtækisins eru staðfestar af ${assessor} og birtar hér ${placePublication}.`,
        "comment_en": `The company's sustainability information is verified by ${assessor} and published here ${placePublication}.`,
        "assessor" : assessor,
        "placePublication": placePublication,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="row" style={{alignItems: 'center'}}>
      <div className="col-5">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.assessor')}</Label>
        <Input
          id="text"
          type="text"
          className="form-control goalField"
          value={assessor}
          onChange={(event) =>{setAssessor(event.target.value)}}
          invalid={error && assessor.length === 0}
        />
      </div>
      <div className="col-5">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.place_of_publication')}</Label>
        <Input
          id="text"
          type="text"
          className="form-control goalField"
          value={placePublication}
          onChange={(event) =>{setPlacePublication(event.target.value)}}
          invalid={error && placePublication.length === 0}
        />
      </div>
      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" style={{marginTop: '40px'}} onClick={()=>saveEditComment()}><Check/></div>
      </div>
    </div>
  )
};

export default GreenStep207