import React from 'react';
import {Close, Edit} from "@mui/icons-material";
import i18n from "../../../../i18n";


const GreenStepDefaultShow = (props) => {
  const currentLang = i18n.language || 'is';
  const {comment, commentIndex, commentItem, isDeleted, greenStep, dataType} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  return (
    <React.Fragment>
      <p className="completedCommentText">
        <span>{commentItem["comment_"+currentLang] ? commentItem["comment_"+currentLang] : commentItem.comment}</span>
        {(commentItem.customComment || (userData && userData.user.id === commentItem.user_id)) && !isDeleted &&
          <span
            className="completedAddNewIcon categoryBgColor editCommentBtn hover-effect"
            onClick={() => {
              props.changeCommentState(commentItem.comment, greenStep.new_company_green_steps[0].id, commentIndex)
            }}
          >
            <Edit/>
          </span>
        }
      </p>
      {!commentItem.hasOwnProperty('customComment') && userData && userData.user.id === commentItem.user_id && !isDeleted &&
        <p
          className="companyCompletedIcon categoryBorderColor true hover-effect"
          onClick={() => {
            (commentItem.name === "Töluleg áætlun" || commentItem.name === "Skýring á ráðstöfunum") ?
              props.toggleItem()
              :
              props.removeComment(greenStep.new_company_green_steps[0].id, commentIndex, comment, greenStep.new_green_step_category_id, dataType)
          }}
        >
          <Close/>
        </p>
      }
    </React.Fragment>
  )
};

export default GreenStepDefaultShow