import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import {useDispatch} from "react-redux";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";

const GreenStep62 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [totalCar, setTotalCar] = useState(0);
  const [electricCar, setElectricCar] = useState(0);
  const [error, setError] = useState(false);


  const handleCheck = () => {
    if(totalCar>0 && electricCar>0) {
      let score = Math.round((electricCar / totalCar)*item.score);

      let data = {
        "new_green_step_id": item.id,
        "comment": [{
          "comment": `${electricCar} af ${totalCar} bílum ganga fyrir rafmagni`,
          "comment_en": `${electricCar} of ${totalCar} cars run on electricity`,
          "totalCar": totalCar,
          "electricCar": electricCar,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        }],
        "slider_score": score,
        "slider_score_percentage": score,
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    } else {
      setError(true);
    }
  };


  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="customGreenStep">
        <p className="categoryTextColor"> {t('pluses_page.number_of_company_cars')} </p>
        <p>
          <Input
            id="text"
            type="number"
            className="form-control goalField"
            value={totalCar}
            onChange={(event) =>{setTotalCar(event.target.value)}}
            invalid={error && totalCar === 0}
          />
        </p>

        <br/>
        <p className="categoryTextColor"> {t('pluses_page.number_of_company_cars_electricity')} </p>

        <div className="row">
          <div className="col-10">
            <label htmlFor="">{t('pluses_page.electricity')}</label>
            <Input
              id="text"
              type="number"
              className="form-control goalField"
              value={electricCar}
              min={0}
              max={totalCar}
              onChange={(event) =>{event.target.value > totalCar ? setElectricCar(totalCar) : setElectricCar(event.target.value)}}
              invalid={error && electricCar === 0}
            />
          </div>
          <div className="col-2 save">
            <div className="saveBtn categoryBgColor" onClick={()=>handleCheck()}>
              {t('save')}
            </div>
          </div>
        </div>
      </div>



    </React.Fragment>
  )
};

export default GreenStep62