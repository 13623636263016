import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import {useDispatch} from "react-redux";
import Slider from "@mui/material/Slider";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";
import IrrelevantSwitch from "../irrelevantSwitch";
const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep144 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [employees, setEmployees] = useState(0);
  const [study, setStudy] = useState(0);
  const [error, setError] = useState(false);


  const handleCheck = () => {
    if(employees>0 && study>0) {
      let data = {
        "new_green_step_id": item.id,
        "comment": [{
          "comment": `${study}% af starfsfólki sem hefur ekki íslensku að móðurmáli, er styrkt til íslenskunáms.`,
          "comment_en": `${study}% of non-native Icelandic employees are supported in studying Icelandic.`,
          "employees": employees,
          "study": study,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        }],
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    } else {
      setError(true);
    }
  };


  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </div>

      <IrrelevantSwitch item={item} companyGreenStep={companyGreenStep}/>

      <div className="customGreenStep">
        <p className="categoryTextColor">{t('pluses_page.non-native_icelandic_employees')}</p>
        <p>
          <Input
            id="text"
            type="number"
            className="form-control goalField"
            value={employees}
            onChange={(event) =>{setEmployees(event.target.value)}}
            invalid={error && employees === 0}
          />
        </p>

        <div className="row">
          <div className="col-10">
            <div className="row">
              <div className="slider col-sm-10 col-md-8 col-lg-6 col-xl-4 col-xxl-4">
                <div className="title categoryTextColor">{t('pluses_page.percentage_of_staff_received_grant')}</div>
                <Slider
                  value={study}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => `${value}%`}
                  step={1}
                  min={0}
                  max={100}
                  onChange={(e, newValue) => setStudy(newValue)}
                />
                <p className="sliderFinish categoryTextColor">100%</p>
              </div>
            </div>
          </div>

          <div className="col-2 save">
            <div className="saveBtn sliderBtn categoryBgColor" onClick={() => handleCheck()}>
              {t('save')}
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
};

export default GreenStep144