import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Check} from '@mui/icons-material';
import {Input, Label} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {editComment} from "../../../../store/actions/greenStep";
import {customDropdownStyles} from "../../../../helpers/dropdown";
import Select from "react-select";
import {useTranslation} from "react-i18next";

const yearOptions = () => {
  let years = [];
  for(let i=0; i<=13; i++) {
    years.push({label: 2010+i, value: 2010+i})
  }
  return years;
};


const GreenStep215 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [percentage, setPercentage] = useState('');
  const [year, setYear] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if(props.item) {
      setPercentage(item.percentage);
      setYear(item.year);
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(percentage>0 && year>0) {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `Árið ${year} mældist starfsánægja fyrirtækisins ${percentage}%`,
        "comment_en": `In ${year}, the company's job satisfaction was measured at ${percentage}%`,
        "percentage": percentage,
        "year": year,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="row customGreenStep210Completed" style={{alignItems: 'center'}}>
      <div className="col-3">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.survey_result')}</Label>
        <div className="inputCol">
          <Input
            id="text"
            type="number"
            placeholder="Niðurstaða könnunar"
            className="form-control goalField"
            value={percentage}
            onChange={(event) => {
              event.target.value <= 100 ? setPercentage(event.target.value) : setPercentage(100)
            }}
            invalid={error && percentage.length === 0}
          />
          <span className="categoryTextColor">%</span>
        </div>
      </div>


      <div className="col-3">
        <Label className="col-form-label categoryTextColor">Ár</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => setYear(event.value)}
          styles={customDropdownStyles('#FD974F')}
          options={yearOptions()}
          value={{label: year, value: year}}
          className={`react-select ${error && year.length === 0 && 'has-error'}`}
        />
      </div>

      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" style={{marginTop: '40px'}} onClick={()=>saveEditComment()}><Check/></div>
      </div>
    </div>
  )
};

export default GreenStep215