import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "../../../../i18n";
import ScoreAndFavorite from "../scoreAndFavorite";
import RenderDropdownContainer from "../renderDropdownContainer";
import IrrelevantSwitch from "../irrelevantSwitch";
const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep155 = (props) => {
  const { t } = useTranslation();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const currentLang = i18n.language || 'is';

  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <p className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </p>

      <IrrelevantSwitch item={item} companyGreenStep={companyGreenStep}/>

      <div className="confirm_container">
        <div className="confirm_title categoryTextColor">
          {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
        </div>
        <div className="confirm_title ">
          {currentLang === 'en' && item.pdf_description2_en ? item.pdf_description2_en : item.pdf_description2}
        </div>

        <RenderDropdownContainer mainCategoryIndex={item.main_category-1} itemId={item.id}/>

      </div>


    </React.Fragment>
  )
};

export default GreenStep155