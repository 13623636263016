import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Check} from '@mui/icons-material';
import {Input, Label} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {editComment} from "../../../../store/actions/greenStep";
import {useTranslation} from "react-i18next";

const GreenStep144 = (props) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const [error, setError] = useState(false);
  const [employees, setEmployees] = useState(0);
  const [study, setStudy] = useState(0);

  useEffect(() => {
    if(props.item) {
      setEmployees(item.employees);
      setStudy(item.study)
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(employees>0 && study>0) {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `${study}% af starfsfólki sem hefur ekki íslensku að móðurmáli, er styrkt til íslenskunáms.`,
        "comment_en": `${study}% of non-native Icelandic employees are supported in studying Icelandic.`,
        "employees": employees,
        "study": study,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));

      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="row" >
      <div className="col-5">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.non-native_icelandic_employees')}</Label>
        <Input
          id="text"
          type="number"
          className="form-control goalField"
          value={employees}
          onChange={(event) =>{setEmployees(event.target.value)}}
          invalid={error && employees === 0}
        />
      </div>

      <div className="col-5">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.percentage_of_staff_received_grant')}</Label>
        <Input
          id="text"
          type="number"
          className="form-control goalField"
          value={study}
          onChange={(event) => {
            setStudy(event.target.value)
          }}
          invalid={error && study === 0}
        />
      </div>
      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" style={{marginTop: '50px'}} onClick={() => saveEditComment()}><Check/></div>
      </div>
    </div>
  )
};

export default GreenStep144